import type { Search } from "index"
import type { ShippingAddressInfo } from "../shippingAddress/shippingAddressService"
import type { Media } from "../media"

export type OrderStatus = 'DRAFT' | 'PENDING_PAYMENT' | 'ACTIVE' | 'CANCELLED' | 'COMPLETED'
export type OrderItemStatus = 'CREATED' | 'IN_FULFILLMENT' | 'SHIPPED' | 'DELIVERED' | 'CANCELLED' | 'RETURNED'

export interface OrderSummary {
  id: number
  customerId: number
  invoiceId?: number
  status: OrderStatus
  items: OrderItemSummary[]
  billingAddress: ShippingAddressInfo
  paymentProfileId: string
  voucher: string
  shippingAddress: ShippingAddressInfo
  shippingCost: number
  total: number
  bankSlipUrl?: string
  pix?: string
  createdAt: string
  updatedAt: string
}

export interface OrderItemSummary {
  id: number
  orderId: number
  productVariantId: number
  status: OrderItemStatus
  description: string
  thumbnail?: Media
  quantity: number
  unitPrice: number
  total: number
  inventoryTransactionId?: number
}

export default {
  searchSelfOrders(search: Search = {}): Promise<OrderSummary[]> {
    return apiGet('/self/ecommerce/order', { query: buildSearchQueryParams(search) })
  },
  findOrder(orderId: number): Promise<OrderSummary> {
    return apiGet(`/self/ecommerce/order/${orderId}`)
  },
  findOrderItems(orderId: number): Promise<OrderItemSummary[]> {
    return apiGet(`/self/ecommerce/order/${orderId}/item`)
  }
}