import type { Search } from "index"
import type { SearchParameters } from "ofetch"

export function buildSearchQueryParams(search: Search): SearchParameters {
  const searchFilters = search.filters?.map(filter => {
    return `${filter.field}:${filter.values.join(',')}`
  }).join(';')

  return {
    query:search.query,
    page: search.page,
    pageSize: search.pageSize,
    orderBy: search.orderBy?.field,
    sortDirection: search.orderBy?.direction,
    filters: searchFilters
  }
}